<template>
  <div class="personal-data">
    <div class="personal-information">
      <v-form ref="form" lazy-validation>
        <!-- Payment information -->
        <h3 class="header-h3">Payment information</h3>

        <!-- edit -->
        <div v-if="edit">
          <v-row>
            <!-- Bank Details -->
            <v-col class="mt-2" cols="12">
              <!-- Bank -->
              <v-card max-width="362" flat>
                <p class="label-text mb-1">Bank</p>

                <v-select
                  v-model="paymentInformation.bank"
                  :rules="paymentInformation.bankRules"
                  :items="getBanksList"
                  :disabled="isDisabledByEmploymentStatus"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-card>

              <!-- Account type -->
              <v-card class="mt-2" max-width="362" flat>
                <p class="label-text mb-1">Account type</p>

                <v-radio-group
                  v-model="paymentInformation.typeOfAccount"
                  :rules="paymentInformation.typeOfAccountRules"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  class="mt-n1"
                >
                  <v-radio
                    v-for="accountType in accountTypes"
                    :key="accountType"
                    :label="`${accountType}`"
                    :value="accountType"
                  ></v-radio>
                </v-radio-group>
              </v-card>

              <!-- Account number -->
              <v-card class="mt-2 mb-n8" width="362" flat>
                <p class="label-text mb-1">Account number</p>

                <v-text-field
                  v-model="paymentInformation.bankAccountNumber"
                  :rules="paymentInformation.bankAccountNumberRules"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  class="inputPrice"
                  type="number"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- Bank -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">Bank</p>
            <h6 class="input-text">
              {{
                auth &&
                auth.user &&
                auth.user.banking_details &&
                auth.user.banking_details.bank_name
                  ? auth.user.banking_details.bank_name
                  : ""
              }}
            </h6>
          </div>

          <!-- Account type  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Account type</p>
            <h6
              v-if="
                auth &&
                auth.user &&
                auth.user.banking_details &&
                auth.user.banking_details.account_type &&
                auth.user.banking_details.account_type == 'Cheque/Current'
              "
              class="input-text"
            >
              Current
            </h6>
            <h6 v-else class="input-text">
              {{
                auth &&
                auth.user &&
                auth.user.banking_details &&
                auth.user.banking_details.account_type
                  ? auth.user.banking_details.account_type
                  : ""
              }}
            </h6>
          </div>

          <!-- Account number	  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Account number</p>
            <h6 class="input-text">
              {{
                auth &&
                auth.user &&
                auth.user.banking_details &&
                auth.user.banking_details.account_number
                  ? auth.user.banking_details.account_number
                  : ""
              }}
            </h6>
          </div>
        </div>

        <!-- Employment -->
        <h3 class="header-h3 mt-11">Employment</h3>

        <!-- edit -->
        <div v-if="edit">
          <v-row>
            <v-col class="mt-4" cols="12">
              <!-- Employment status -->
              <v-card max-width="362" flat>
                <p class="label-text mb-1">Employment status</p>

                <v-select
                  v-model="additionalInformation.employmentStatus"
                  :rules="additionalInformation.employmentStatusRules"
                  :items="getEmploymentTypesList"
                  :disabled="isDisabledByBank"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-card>

              <!-- Salary day -->
              <v-card class="mt-2" max-width="170" flat>
                <p class="label-text mb-1">Salary day</p>

                <v-select
                  v-model="additionalInformation.nextIncomeDate.day"
                  :rules="additionalInformation.nextIncomeDate.dayRules"
                  :items="getDaysList"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-card>

              <!--  Employer name -->
              <v-card class="mt-2" width="362" flat>
                <p class="label-text mb-1">Employer name</p>

                <v-text-field
                  v-model="additionalInformation.employerName"
                  :rules="additionalInformation.employerNameRules"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>

              <!--  Position -->
              <v-card class="mt-2" width="362" flat>
                <p class="label-text mb-1">Position</p>

                <v-text-field
                  v-model="additionalInformation.position"
                  :rules="additionalInformation.positionRules"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>

              <!--  Work phone -->
              <v-card class="mt-2" width="362" flat>
                <p class="label-text mb-1">Work phone</p>

                <v-text-field
                  v-model="additionalInformation.workPhone"
                  :rules="additionalInformation.workPhoneRules"
                  :disabled="isDisabledByBank || isDisabledByEmploymentStatus"
                  maxlength="12"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- Employment status -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">Employment status</p>
            <h6 class="input-text">
              {{ auth.user.employment_type }}
            </h6>
          </div>

          <!-- Salary day -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Salary day</p>
            <h6 class="input-text">
              {{ auth.user.salary_day }}
            </h6>
          </div>

          <!-- Employer name -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Employer name</p>
            <h6 class="input-text">
              {{ auth.user.employer_name }}
            </h6>
          </div>

          <!-- Position -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Position</p>
            <h6 class="input-text">
              {{ auth.user.occupation }}
            </h6>
          </div>

          <!-- Work phone -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Work phone</p>
            <h6 class="input-text">
              {{
                $helpers.formatMobileNumber(auth.user.employer_contact_number)
              }}
            </h6>
          </div>
        </div>

        <!-- edit -->
        <div v-if="edit" class="d-flex flex-column flex-sm-row">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <!-- Save -->
            <v-btn
              @click="validateForm()"
              class="b-btn-text white--text text-capitalize mt-4"
              width="169"
              height="48"
              color="primary"
              :disabled="!readyToSubmit"
            >
              Save
            </v-btn>

            <!-- Reject -->
            <v-btn
              @click="edit = !edit"
              class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
              width="169"
              height="48"
              color="primary"
              outlined
            >
              Reject
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <!-- Save -->
              <v-btn
                @click="validateForm()"
                class="b-btn-text white--text text-capitalize mt-4"
                width="100%"
                height="48"
                color="primary"
                :disabled="!readyToSubmit"
              >
                Save
              </v-btn>

              <!-- Reject -->
              <v-btn
                @click="edit = !edit"
                class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                Reject
              </v-btn>
            </v-card>
          </div>
        </div>

        <div v-else class="mt-8">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <v-btn
              @click="editData()"
              width="175"
              height="48"
              color="primary"
              outlined
            >
              <div class="d-flex">
                <v-img
                  alt="pen"
                  class="pen"
                  src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                  max-width="24"
                  contain
                />

                <h6 class="b-btn-text primary--text text-capitalize ml-2">
                  Edit
                </h6>
              </div>
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <v-btn
                @click="editData()"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                <div class="d-flex">
                  <v-img
                    alt="pen"
                    class="pen"
                    src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                    max-width="24"
                    contain
                  />

                  <h6 class="b-btn-text primary--text text-capitalize ml-2">
                    Edit
                  </h6>
                </div>
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-form>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansSolvencyinformation",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,
      edit: false,
      readyToSubmit: false,

      employmentDetailsWasSend: true,
      bankingDetailsWasSend: true,
      isDisabledByBank: false,
      isDisabledByEmploymentStatus: false,

      // Payment Information
      paymentInformation: {
        // Bank
        bank: "",
        bankRules: [
          (v) => !!v || "",
          (v) =>
            v !== "Other" ||
            "Unfortunately we currently do not provide loans for customers using other banks",
          (v) =>
            (!!v && !this.paymentInformation.bankHasError) ||
            this.paymentInformation.bankErrorMessage,
        ],
        bankHasError: false,
        bankErrorMessage: "bank",

        // Type Of Account
        typeOfAccount: "",
        typeOfAccountRules: [
          (v) => !!v || "Required",
          (v) =>
            (!!v && !this.paymentInformation.typeOfAccountHasError) ||
            this.paymentInformation.typeOfAccountErrorMessage,
        ],
        typeOfAccountHasError: false,
        typeOfAccountErrorMessage: "type of account",

        // Bank Account Number
        bankAccountNumber: "",
        bankAccountNumberRules: [
          (v) => !!v || "Required",
          (v) => /^[0-9]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) =>
            (!!v && !this.paymentInformation.bankAccountNumberHasError) ||
            this.paymentInformation.bankAccountNumberErrorMessage,
        ],
        bankAccountNumberHasError: false,
        bankAccountNumberErrorMessage: "bank account number",
      },

      accountTypes: ["Savings", "Current"],

      // Additional Information
      additionalInformation: {
        // Employment Status
        employmentStatus: "",
        employmentStatusRules: [
          (v) => !!v || "Required",
          (v) =>
            v == "Full-time" ||
            v == "Part-time" ||
            "Currently this employment status does not qualify for our services",
          (v) =>
            (!!v && !this.additionalInformation.employmentStatusHasError) ||
            this.additionalInformation.employmentStatusErrorMessage,
        ],
        employmentStatusHasError: false,
        employmentStatusErrorMessage: "employment status",

        // Next Income Date
        nextIncomeDate: {
          // Day
          day: null,
          dayRules: [
            (v) => !!v || "Required",
            (v) =>
              (!!v && !this.additionalInformation.nextIncomeDate.dayHasError) ||
              this.additionalInformation.nextIncomeDate.dayErrorMessage,
          ],
          dayHasError: false,
          dayErrorMessage: "day",
        },

        // The Emergency Phone Number
        theEmergencyPhoneNumber: "",
        theEmergencyPhoneNumberRules: [
          (v) => !!v || "Required",
          (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Must be 10 numbers",
          (v) =>
            (!!v &&
              !this.additionalInformation.theEmergencyPhoneNumberHasError) ||
            this.additionalInformation.theEmergencyPhoneNumberErrorMessage,
        ],
        theEmergencyPhoneNumberHasError: false,
        theEmergencyPhoneNumberErrorMessage: "the emergency phone number",

        // Years Of Employment
        yearsOfEmployment: "",
        yearsOfEmploymentRules: [
          (v) => !!v || "Required",
          (v) =>
            (!!v && !this.additionalInformation.yearsOfEmploymentHasError) ||
            this.additionalInformation.yearsOfEmploymentErrorMessage,
        ],
        yearsOfEmploymentHasError: false,
        yearsOfEmploymentErrorMessage: "years of employment",

        // Month Of Employment
        monthOfEmployment: "",
        monthOfEmploymentRules: [
          (v) => !!v || "Required",
          (v) =>
            (!!v && !this.additionalInformation.monthOfEmploymentHasError) ||
            this.additionalInformation.monthOfEmploymentErrorMessage,
        ],
        monthOfEmploymentHasError: false,
        monthOfEmploymentErrorMessage: "month of employment",

        // Position
        position: "",
        positionRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.positionHasError) ||
            this.additionalInformation.positionErrorMessage,
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
          (v) => v.length <= 50 || "Can not be more than 50 characters",
        ],
        positionHasError: false,
        positionErrorMessage: "position",

        // Employer Name
        employerName: "",
        employerNameRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.employerNameHasError) ||
            this.additionalInformation.employerNameErrorMessage,
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
          (v) => v.length <= 100 || "Can not be more than 100 characters",
        ],
        employerNameHasError: false,
        employerNameErrorMessage: "employer name",

        // Work Phone
        workPhone: "",
        workPhoneRules: [
          (v) => !!v || "Required",
          (v) => /^[0-9+\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Must be 10 numbers",
          (v) =>
            (!!v && !this.additionalInformation.workPhoneHasError) ||
            this.additionalInformation.workPhoneErrorMessage,
        ],
        workPhoneHasError: false,
        workPhoneErrorMessage: "work phone",

        // Frequency Of Income
        frequencyOfIncome: "monthly",
        frequencyOfIncomeRules: [
          (v) => !!v || "Required",
          (v) =>
            (!!v && !this.additionalInformation.frequencyOfIncomeHasError) ||
            this.additionalInformation.frequencyOfIncomeErrorMessage,
        ],
        frequencyOfIncomeHasError: false,
        frequencyOfIncomeErrorMessage: "frequency of income",
      },
    };
  },

  watch: {
    // paymentInformation.bank
    "paymentInformation.bank"(val) {
      if (this.paymentInformation.bankHasError) {
        this.paymentInformation.bankHasError = false;
        this.paymentInformation.bankErrorMessage = "";
      }

      this.bankingDetailsWasSend = false;

      this.setIsDisabledByBank(val);

      this.setReadyToSubmit();
    },

    // paymentInformation.typeOfAccount
    "paymentInformation.typeOfAccount"() {
      if (this.paymentInformation.typeOfAccountHasError) {
        this.paymentInformation.typeOfAccountHasError = false;
        this.paymentInformation.typeOfAccountErrorMessage = "";
      }

      this.bankingDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // paymentInformation.bankAccountNumber
    "paymentInformation.bankAccountNumber"() {
      if (this.paymentInformation.bankAccountNumberHasError) {
        this.paymentInformation.bankAccountNumberHasError = false;
        this.paymentInformation.bankAccountNumberErrorMessage = "";
      }

      this.bankingDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.employmentStatus
    "additionalInformation.employmentStatus"(val) {
      if (this.additionalInformation.employmentStatusHasError) {
        this.additionalInformation.employmentStatusHasError = false;
        this.additionalInformation.employmentStatusErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setIsDisabledByEmploymentStatus(val);

      this.setReadyToSubmit();
    },

    // additionalInformation.nextIncomeDate.day
    "additionalInformation.nextIncomeDate.day"() {
      if (this.additionalInformation.nextIncomeDate.dayHasError) {
        this.additionalInformation.nextIncomeDate.dayHasError = false;
        this.additionalInformation.nextIncomeDate.dayErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.theEmergencyPhoneNumber
    "additionalInformation.theEmergencyPhoneNumber"() {
      if (this.additionalInformation.theEmergencyPhoneNumberHasError) {
        this.additionalInformation.theEmergencyPhoneNumberHasError = false;
        this.additionalInformation.theEmergencyPhoneNumberErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.yearsOfEmployment
    "additionalInformation.yearsOfEmployment"() {
      if (this.additionalInformation.yearsOfEmploymentHasError) {
        this.additionalInformation.yearsOfEmploymentHasError = false;
        this.additionalInformation.yearsOfEmploymentErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.monthOfEmployment
    "additionalInformation.monthOfEmployment"() {
      if (this.additionalInformation.monthOfEmploymentHasError) {
        this.additionalInformation.monthOfEmploymentHasError = false;
        this.additionalInformation.monthOfEmploymentErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.position
    "additionalInformation.position"() {
      if (this.additionalInformation.positionHasError) {
        this.additionalInformation.positionHasError = false;
        this.additionalInformation.positionErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.employerName
    "additionalInformation.employerName"() {
      if (this.additionalInformation.employerNameHasError) {
        this.additionalInformation.employerNameHasError = false;
        this.additionalInformation.employerNameErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // additionalInformation.workPhone
    "additionalInformation.workPhone"(val) {
      if (this.additionalInformation.workPhoneHasError) {
        this.additionalInformation.workPhoneHasError = false;
        this.additionalInformation.workPhoneErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.additionalInformation.workPhone =
        this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // additionalInformation.frequencyOfIncome
    "additionalInformation.frequencyOfIncome"() {
      if (this.additionalInformation.frequencyOfIncomeHasError) {
        this.additionalInformation.frequencyOfIncomeHasError = false;
        this.additionalInformation.frequencyOfIncomeErrorMessage = "";
      }

      this.employmentDetailsWasSend = false;

      this.setReadyToSubmit();
    },
  },

  computed: {
    ...mapState({ auth: "auth", personalData: "personalData" }),

    ...mapGetters({
      getBanksList: "list/getBanksList",
      getBankAccountTypesList: "list/getBankAccountTypesList",
      getEmploymentTypesList: "list/getEmploymentTypesList",
      getDaysList: "list/getDaysList",
    }),
  },

  created() {
    this.bankingDetailsWasSend = true;
    this.employmentDetailsWasSend = true;

    this.readyToSubmit = false;

    this.setShowMessage(false);

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getSessionExpires: "auth/getSessionExpires",
      getCurrentState: "registration/getCurrentState",
    }),

    ...mapMutations({
      setShowMessage: "personalData/setShowMessage",

      setBankingAndEmploymentTabHasSuccess:
        "personalData/setBankingAndEmploymentTabHasSuccess",
    }),

    editData() {
      this.edit = !this.edit;

      this.paymentInformation.bank = this.auth.user.banking_details.bank_name;
      this.paymentInformation.typeOfAccount =
        this.auth.user.banking_details.account_type;
      this.paymentInformation.bankAccountNumber =
        this.auth.user.banking_details.account_number;

      this.additionalInformation.employmentStatus =
        this.auth.user.employment_type;
      this.additionalInformation.position = this.auth.user.occupation;
      this.additionalInformation.employerName = this.auth.user.employer_name;
      this.additionalInformation.workPhone = this.$helpers.formatMobileNumber(
        this.auth.user.employer_contact_number
      );

      this.additionalInformation.nextIncomeDate.day = this.auth.user.salary_day;

      if (this.auth.user.banking_details.account_type == "Cheque/Current") {
        this.paymentInformation.typeOfAccount = "Current";
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.paymentInformation.bank === "" ||
        this.paymentInformation.bank === null ||
        this.paymentInformation.typeOfAccount === "" ||
        this.paymentInformation.typeOfAccount === null ||
        this.paymentInformation.bankAccountNumber === "" ||
        this.paymentInformation.bankAccountNumber === null ||
        this.additionalInformation.employmentStatus === "" ||
        this.additionalInformation.employmentStatus === null ||
        this.additionalInformation.nextIncomeDate.day === "" ||
        this.additionalInformation.nextIncomeDate.day === null ||
        this.additionalInformation.employerName === "" ||
        this.additionalInformation.employerName === null ||
        this.additionalInformation.position === "" ||
        this.additionalInformation.position === null ||
        this.additionalInformation.workPhone === "" ||
        this.additionalInformation.workPhone === null ||
        !this.$refs.form.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // setIsDisabledByBank
    setIsDisabledByBank(val) {
      if (val == "Other") {
        this.isDisabledByBank = true;
      } else {
        this.isDisabledByBank = false;
      }
    },

    // setIsDisabledByEmploymentStatus
    setIsDisabledByEmploymentStatus(val) {
      if (val == "Full-time" || val == "Part-time") {
        this.isDisabledByEmploymentStatus = false;
      } else {
        this.isDisabledByEmploymentStatus = true;
      }
    },

    // validateForm
    async validateForm() {
      this.setShowMessage(false);

      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.employmentDetailsWasSend = false;
        this.bankingDetailsWasSend = false;

        var allowSendBankingDetails = this.allowSendingBankingDetails();
        var allowSendEmploymentDetails = this.allowSendingEmploymentDetails();
        if (allowSendBankingDetails || allowSendEmploymentDetails) {
          // BankingDetails
          if (allowSendBankingDetails) {
            // console.log("sendBankingDetails");
            await this.sendBankingDetails();
          } else {
            this.bankingDetailsWasSend = true;
          }

          // EmploymentDetails
          if (allowSendEmploymentDetails) {
            // console.log("sendEmploymentDetails");
            await this.sendEmploymentDetails();
          } else {
            this.employmentDetailsWasSend = true;
          }

          this.complete();
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "You did not make any changes.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // allowSendingBankingDetails
    allowSendingBankingDetails() {
      var tempTypeOfAccount = this.auth.user.banking_details.account_type;

      if (this.auth.user.banking_details.account_type == "Cheque/Current") {
        tempTypeOfAccount = "Current";
      }

      return (
        this.paymentInformation.bank !=
          this.auth.user.banking_details.bank_name ||
        this.paymentInformation.typeOfAccount != tempTypeOfAccount ||
        this.paymentInformation.bankAccountNumber !=
          this.auth.user.banking_details.account_number
      );
    },

    // allowSendingEmploymentDetails
    allowSendingEmploymentDetails() {
      var tempWorkPhone = this.$helpers.formatInputMobileNumber(
        this.$helpers.formatMobileNumber(this.auth.user.employer_contact_number)
      );

      return (
        this.additionalInformation.employmentStatus !=
          this.auth.user.employment_type ||
        this.additionalInformation.position != this.auth.user.occupation ||
        this.additionalInformation.employerName !=
          this.auth.user.employer_name ||
        this.additionalInformation.nextIncomeDate.day !=
          this.auth.user.salary_day ||
        this.additionalInformation.workPhone != tempWorkPhone
      );
    },

    // complete
    async complete() {
      if (this.bankingDetailsWasSend && this.employmentDetailsWasSend) {
        await this.getApiUserDetails();

        // this.bankingDetailsWasSend = false;
        // this.employmentDetailsWasSend = false;
        this.edit = false;

        this.setShowMessage(true);

        this.$vuetify.goTo(0);
      }
    },

    // Send Banking Details
    async sendBankingDetails() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      var typeOfAccount = "Savings";

      if (this.paymentInformation.typeOfAccount == "Current") {
        typeOfAccount = "Cheque/Current";
      }

      try {
        const res = await this.axios.post("bank-detail", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          bank_name: this.paymentInformation.bank,
          bank_account_type: typeOfAccount,
          bank_account_number: this.paymentInformation.bankAccountNumber,
        });

        if (res.status == 200) {
          this.bankingDetailsWasSend = true;
          // this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("data", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("bank_name" in data) {
            this.paymentInformation.bankHasError = true;
            this.paymentInformation.bankErrorMessage = data.bank_name[0];
          }

          if ("bank_account_type" in data) {
            this.paymentInformation.typeOfAccountHasError = true;
            this.paymentInformation.typeOfAccountErrorMessage =
              data.bank_account_type[0];

            // console.log(this.paymentInformation.typeOfAccountErrorMessage);
            // console.log(this.paymentInformation.typeOfAccountHasError);
          }

          if ("bank_account_number" in data) {
            this.paymentInformation.bankAccountNumberHasError = true;
            this.paymentInformation.bankAccountNumberErrorMessage =
              data.bank_account_number[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.bankingDetailsWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Employment Details
    async sendEmploymentDetails() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("employment-detail", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          employment_type: this.additionalInformation.employmentStatus,
          occupation: this.additionalInformation.position,
          employer_name: this.additionalInformation.employerName,
          employer_contact_number: this.additionalInformation.workPhone,
          salary_day: this.additionalInformation.nextIncomeDate.day,
          salary_frequency: this.additionalInformation.frequencyOfIncome,
        });

        if (res.status == 200) {
          this.employmentDetailsWasSend = true;
          // this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("data", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("employment_type" in data) {
            this.additionalInformation.employmentStatusHasError = true;
            this.additionalInformation.employmentStatusErrorMessage =
              data.employment_type[0];
          }

          if ("occupation" in data) {
            this.additionalInformation.positionHasError = true;
            this.additionalInformation.positionErrorMessage =
              data.occupation[0];
          }

          if ("employer_name" in data) {
            this.additionalInformation.employerNameHasError = true;
            this.additionalInformation.employerNameErrorMessage =
              data.employer_name[0];
          }

          if ("employer_contact_number" in data) {
            this.additionalInformation.workPhoneHasError = true;
            this.additionalInformation.workPhoneErrorMessage =
              data.employer_contact_number[0];
          }

          if ("salary_day" in data) {
            this.additionalInformation.nextIncomeDate.dayHasError = true;
            this.additionalInformation.nextIncomeDate.dayErrorMessage =
              data.salary_day[0];
          }

          if ("salary_frequency" in data) {
            this.additionalInformation.frequencyOfIncomeHasError = true;
            this.additionalInformation.frequencyOfIncomeErrorMessage =
              data.salary_frequency[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.employmentDetailsWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
