<template>
  <div class="personal-data">
    <div class="password-tab">
      <v-form ref="form" lazy-validation>
        <!-- edit -->
        <div v-if="edit">
          <v-row>
            <!-- Password -->
            <v-col class="mt-2" cols="12">
              <v-card max-width="362" flat>
                <p class="label-text mb-1">New password</p>

                <v-text-field
                  @click:append="showPassword = !showPassword"
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>

              <v-card class="mt-n4" width="362" flat>
                <!-- Password Rule 1 -->
                <p class="b-body-2 success--text" v-if="passwordRule1Correct">
                  <v-icon size="22" class="ma-n0" color="success">
                    mdi-check
                  </v-icon>

                  <span class="ml-1">8 to 30 characters</span>
                </p>
                <p class="b-body-2" v-else>
                  <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                  <span class="ml-1">8 to 30 characters</span>
                </p>

                <!-- Password Rule 2 -->
                <p
                  class="b-body-2 mt-n3 success--text"
                  v-if="passwordRule2Correct"
                >
                  <v-icon size="22" class="ma-n0" color="success">
                    mdi-check
                  </v-icon>

                  <span class="ml-1"
                    >Сontains lowercase and uppercase English letters
                  </span>
                </p>
                <p class="b-body-2 mt-n3" v-else>
                  <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                  <span class="ml-1"
                    >Сontains lowercase and uppercase English letters</span
                  >
                </p>

                <!-- Password Rule 3 -->
                <p
                  class="b-body-2 mt-n3 success--text"
                  v-if="passwordRule3Correct"
                >
                  <v-icon size="22" class="ma-n0" color="success">
                    mdi-check
                  </v-icon>

                  <span class="ml-1">Сontains letters and numbers</span>
                </p>
                <p class="b-body-2 mt-n3" v-else>
                  <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                  <span class="ml-1">Сontains letters and numbers</span>
                </p>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-row>
            <!-- Password -->
            <v-col cols="12">
              <v-card max-width="362" flat>
                <!-- Password -->
                <div class="mt-2">
                  <p class="b-body-1 gray800--text mb-0 pb-0">Password</p>
                  <h6 class="input-text">•••••••••••••</h6>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <!-- edit -->
        <div v-if="edit" class="d-flex flex-column flex-sm-row">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <!-- Save -->
            <v-btn
              @click="validateFormForDesktop()"
              class="b-btn-text white--text text-capitalize mt-4"
              width="169"
              height="48"
              color="primary"
              :disabled="!readyToSubmit"
            >
              Save
            </v-btn>

            <!-- Reject -->
            <v-btn
              @click="edit = !edit"
              class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
              width="169"
              height="48"
              color="primary"
              outlined
            >
              Reject
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <!-- Save -->
              <v-btn
                @click="validateFormForMobile()"
                class="b-btn-text white--text text-capitalize mt-4"
                width="100%"
                height="48"
                color="primary"
                :disabled="!readyToSubmit"
              >
                Save
              </v-btn>

              <!-- Reject -->
              <v-btn
                @click="edit = !edit"
                class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                Reject
              </v-btn>
            </v-card>
          </div>
        </div>

        <div v-else class="mt-8">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <v-btn
              @click="editData()"
              width="175"
              height="48"
              color="primary"
              outlined
            >
              <div class="d-flex">
                <v-img
                  alt="pen"
                  class="pen"
                  src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                  max-width="24"
                  contain
                />

                <h6 class="b-btn-text primary--text text-capitalize ml-2">
                  Edit
                </h6>
              </div>
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <v-btn
                @click="editData()"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                <div class="d-flex">
                  <v-img
                    alt="pen"
                    class="pen"
                    src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                    max-width="24"
                    contain
                  />

                  <h6 class="b-btn-text primary--text text-capitalize ml-2">
                    Edit
                  </h6>
                </div>
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-form>

      <!-- Desktop -->
      <v-dialog v-model="dialog" width="600" persistent>
        <div class="card white">
          <div class="dialog-card pa-3">
            <v-card class="pa-3" flat>
              <!-- Confirming a password change -->
              <h3 class="header-h3">Confirming a password change</h3>
              <p class="b-body-1 gray800--text">
                The code was sent to the number
                <span class="font-weight-bold gray900--text">
                  {{ $helpers.formatMobileNumber(auth.user.cellular) }}
                </span>
              </p>

              <!-- SMS-code -->
              <p class="label-text mb-1">SMS-code</p>

              <div class="d-flex flex-column flex-sm-row align-sm-center">
                <v-card width="170" flat>
                  <!-- SMS-code -->
                  <v-form ref="form2" lazy-validation>
                    <v-text-field
                      class="py-0 my-0 inputPrice"
                      v-model="otp"
                      :rules="otpRules"
                      type="number"
                      onKeyPress="if(this.value.length==5) return false;"
                      height="48px"
                      full-width
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-form>
                </v-card>

                <!-- Get a new code -->
                <!-- allowOtpReSend -->
                <p v-if="!allowOtpReSend" class="b-body-1 ml-sm-5 mt-sm-n1">
                  <span class="font-weight-bold gray600--text"
                    >Get a new code</span
                  >
                  <countdown :time="time">
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </p>

                <!-- allowOtpReSend -->
                <p
                  v-else
                  @click="resetPasswordOtp()"
                  class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
                >
                  Get a new code
                </p>
              </div>
            </v-card>

            <v-img
              @click="dialog = false"
              alt="times"
              class="times pointer"
              src="@/assets/img/dashboard/pages/personal-data/times.svg"
              max-width="24"
              contain
            />
          </div>
        </div>
      </v-dialog>

      <!-- Mobile -->
      <v-bottom-sheet v-model="bottomSheet">
        <v-sheet class="px-6 py-3 card2">
          <!-- Icon -->
          <div class="d-flex justify-center">
            <v-card color="gray400" width="48" height="4" flat tile> </v-card>
          </div>

          <div class="mt-4 pb-1">
            <!-- Confirming a password change -->
            <h3 class="desktop-h1">Confirming a password change</h3>
            <p class="b-body-1 gray800--text mt-4">
              The code was sent to the number
              <span class="font-weight-bold gray900--text text-no-wrap">
                {{ $helpers.formatMobileNumber(auth.user.cellular) }}
              </span>
            </p>

            <!-- SMS-code -->
            <p class="label-text mb-1">SMS-code</p>

            <div class="d-flex flex-column flex-sm-row align-sm-center">
              <v-card width="170" flat>
                <!-- SMS-code -->
                <v-form ref="form2" lazy-validation>
                  <v-text-field
                    class="py-0 my-0 inputPrice"
                    v-model="otp"
                    :rules="otpRules"
                    type="number"
                    onKeyPress="if(this.value.length==5) return false;"
                    height="48px"
                    full-width
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-form>
              </v-card>

              <!-- Get a new code -->
              <!-- allowOtpReSend -->
              <p v-if="!allowOtpReSend" class="b-body-1 ml-sm-5 mt-sm-n1">
                <span class="font-weight-bold gray600--text"
                  >Get a new code</span
                >
                <countdown :time="time">
                  <template slot-scope="props">
                    {{ props.minutes }}:{{ props.seconds }}
                  </template>
                </countdown>
              </p>

              <!-- allowOtpReSend -->
              <p
                v-else
                @click="resetPasswordOtp()"
                class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
              >
                Get a new code
              </p>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansPasswordtab",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,
      edit: false,
      dialog: false,
      bottomSheet: false,
      isDesktop: true,

      showCurrentPassword: false,

      // Password
      password: "",
      passwordRules: [
        (v) => !!v || "",
        (v) => (v && !this.passwordHasError) || "",
        (v) => v.length >= 8 || "",
        (v) => v.length <= 30 || "",
        (v) => /^(?=.*?[A-Z])/.test(v) || "",
        (v) => /^(?=.*?[a-z])/.test(v) || "",
        (v) => /^(?=.*?[0-9])/.test(v) || "",
      ],
      passwordHasError: false,
      passwordErrorMessage: "password",
      passwordRule1Correct: false,
      passwordRule2Correct: false,
      passwordRule3Correct: false,

      // Show Password
      showPassword: false,

      error: false,
      otpSend: false,
      allowOtpReSend: false,

      // otp
      otp: "",
      otpRules: [
        (v) => !!v || "",
        (v) => v.length == 5 || "SMS-code is incorrect ",
        (v) => (!!v && !this.otpHasError) || this.otpErrorMessage,
      ],
      otpHasError: false,
      otpErrorMessage: "otp",

      countDown: 0,
      time: 0,
    };
  },

  computed: {
    ...mapState({ auth: "auth", personalData: "personalData" }),
  },

  created() {
    this.setShowMessage(false);

    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // password
    password(val) {
      this.validatePassword(val);
      this.setReadyToSubmit();
    },

    // otp
    otp() {
      if (this.otpHasError) {
        this.otpHasError = false;
        this.otpErrorMessage = "";
      }

      if (this.otp.length == 5) {
        this.resetPassword();
      }
    },
  },

  mounted() {},

  methods: {
    ...mapMutations({
      setShowMessage: "personalData/setShowMessage",

      setPasswordTabHasSuccess: "personalData/setPasswordTabHasSuccess",
    }),

    // editData
    editData() {
      this.edit = !this.edit;
    },

    // validatePassword
    validatePassword(password) {
      // 8 to 30 characters
      if (8 <= password.length && password.length <= 30) {
        this.passwordRule1Correct = true;
      } else {
        this.passwordRule1Correct = false;
      }

      // Сontains lowercase and uppercase English letters
      if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
        this.passwordRule2Correct = true;
      } else {
        this.passwordRule2Correct = false;
      }

      // Сontains letters and numbers
      if (/[A-Za-z]/.test(password) && /[0-9]/.test(password)) {
        this.passwordRule3Correct = true;
      } else {
        this.passwordRule3Correct = false;
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.password === "" ||
        this.password === null ||
        !this.$refs.form.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // validateFormForDesktop
    async validateFormForDesktop() {
      this.isDesktop = true;

      this.validateForm();
    },

    // validateFormForMobile
    async validateFormForMobile() {
      this.isDesktop = false;

      this.validateForm();
    },

    // validateForm
    async validateForm() {
      this.setShowMessage(false);

      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.countDown > 0) {
          if (this.isDesktop) {
            this.dialog = true;
          } else {
            this.bottomSheet = true;
          }
        } else {
          this.resetPasswordOtp();
        }
      }
    },

    // countDownTimer
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.allowOtpReSend = true;
      }
    },

    // complete
    async complete() {
      this.setShowMessage(true);

      this.$vuetify.goTo(0);

      this.edit = false;

      if (this.isDesktop) {
        this.dialog = false;
      } else {
        this.bottomSheet = false;
      }

      this.password = "";
      this.otp = "";
    },

    // resetPasswordOtp
    async resetPasswordOtp() {
      this.overlay = true;

      try {
        let res = await this.axios.post("reset-password-otp", {
          idnumber: this.auth.idnumber,
          cellular: this.auth.user.cellular,
        });

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "OTP Has Been Send To Your Mobile Number",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          if ("dev_otp" in res.data) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Your OTP is " + res.data.dev_otp,
              showConfirmButton: false,
              timer: 30000,
              timerProgressBar: true,
            });
          }

          this.countDown = 2 * 60;
          this.time = 2 * 60 * 1000;

          this.countDownTimer();

          this.otpSend = true;
          this.allowOtpReSend = false;

          if (this.isDesktop) {
            this.dialog = true;
          } else {
            this.bottomSheet = true;
          }

          this.otp = "";

          // console.log("res 200", res);
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401 || status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("cellular" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.cellular[0],
            });
          }

          this.error = true;

          this.$refs.form.validate();
          this.setReadyToSubmit();

          // console.log("error.response", error.response);
          // console.log("data", data);
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // resetPassword
    async resetPassword() {
      this.overlay = true;

      try {
        let res = await this.axios.post("reset-password", {
          idnumber: this.auth.idnumber,
          password_reset_otp: this.otp,
          password: this.password,
          password_verify: this.password,
        });

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your Password Has Been Reset",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          // console.log("res 200", res);
          this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401 || status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("password_reset_otp" in data) {
            this.otpHasError = true;
            this.otpErrorMessage = data.password_reset_otp[0];

            // console.log(this.otpErrorMessage);
          }

          if ("password" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.password[0],
            });
          }

          if ("password_verify" in data) {
            this.passwordHasError = true;
            this.passwordErrorMessage = data.password_verify[0];
          }

          this.$refs.form2.validate();

          // console.log("data", data);
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card {
  position: relative;

  .times {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.card2 {
  /* Neutral / 00 */

  background: #ffffff;
  /* Shadows / XL modal ↑ */

  box-shadow: 0px 0px 32px rgba(61, 61, 61, 0.12),
    0px -32px 32px rgba(61, 61, 61, 0.24);
  border-radius: 8px 8px 0px 0px;
}

.desktop-h1 {
  //styleName: Header desktop/H1;
  font-family: "Raleway";
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
}
</style>
