<template>
  <div class="personal-data">
    <div class="personal-information">
      <v-form ref="form" lazy-validation>
        <!-- Next of kin -->
        <h3 class="header-h3">Next of kin</h3>

        <!-- edit -->
        <div v-if="edit">
          <v-row>
            <!-- Next Of Kin details -->
            <v-col class="mt-2" cols="12">
              <!--  Full names -->
              <v-card max-width="362" flat>
                <p class="label-text mb-1">Full names</p>

                <v-text-field
                  v-model="nextOfKin.fullNames"
                  :rules="nextOfKin.fullNamesRules"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>

              <!--  Mobile phone -->
              <v-card class="mt-2" max-width="170" flat>
                <p class="label-text mb-1">Mobile phone</p>

                <v-text-field
                  v-model="nextOfKin.mobilePhone"
                  :rules="nextOfKin.mobilePhoneRules"
                  maxlength="12"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>

              <!-- Relationship -->
              <v-card class="mt-2 mb-n8" max-width="362" flat>
                <p class="label-text mb-1">Relationship</p>

                <v-select
                  v-model="nextOfKin.relationship"
                  :rules="nextOfKin.relationshipRules"
                  :items="getRelationshipTypesList"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- next_of_kin -->

          <!-- Full names -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">Full names</p>
            <h6 class="input-text">
              {{ auth.user.next_of_kin[0].fullnames }}
            </h6>
          </div>

          <!-- Mobile phone  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Mobile phone</p>
            <h6 class="input-text">
              {{
                $helpers.formatMobileNumber(
                  auth.user.next_of_kin[0].contact_number
                )
              }}
            </h6>
          </div>

          <!-- Relationship	  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Relationship</p>
            <h6 class="input-text">
              {{ auth.user.next_of_kin[0].relationship }}
            </h6>
          </div>
        </div>

        <!-- Other -->
        <h3 class="header-h3 mt-11">Other</h3>

        <div v-if="edit">
          <v-row>
            <!-- Marital status -->
            <v-col class="mt-4" cols="12">
              <v-card max-width="362" flat>
                <p class="label-text mb-1">Marital status</p>

                <v-select
                  v-model="other.maritalStatus"
                  :rules="other.maritalStatusRules"
                  :items="getMaritalStatusTypesList"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- Marital status	 -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">Marital status</p>
            <h6 class="input-text">
              {{ auth.user.marital_status }}
            </h6>
          </div>
        </div>

        <!-- edit -->
        <div v-if="edit" class="d-flex flex-column flex-sm-row">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <!-- Save -->
            <v-btn
              @click="validateForm()"
              class="b-btn-text white--text text-capitalize mt-4"
              width="169"
              height="48"
              color="primary"
              :disabled="!readyToSubmit"
            >
              Save
            </v-btn>

            <!-- Reject -->
            <v-btn
              @click="edit = !edit"
              class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
              width="169"
              height="48"
              color="primary"
              outlined
            >
              Reject
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <!-- Save -->
              <v-btn
                @click="validateForm()"
                class="b-btn-text white--text text-capitalize mt-4"
                width="100%"
                height="48"
                color="primary"
                :disabled="!readyToSubmit"
              >
                Save
              </v-btn>

              <!-- Reject -->
              <v-btn
                @click="edit = !edit"
                class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                Reject
              </v-btn>
            </v-card>
          </div>
        </div>

        <div v-else class="mt-8">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <v-btn
              @click="editData()"
              width="175"
              height="48"
              color="primary"
              outlined
            >
              <div class="d-flex">
                <v-img
                  alt="pen"
                  class="pen"
                  src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                  max-width="24"
                  contain
                />

                <h6 class="b-btn-text primary--text text-capitalize ml-2">
                  Edit
                </h6>
              </div>
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <v-btn
                @click="editData()"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                <div class="d-flex">
                  <v-img
                    alt="pen"
                    class="pen"
                    src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                    max-width="24"
                    contain
                  />

                  <h6 class="b-btn-text primary--text text-capitalize ml-2">
                    Edit
                  </h6>
                </div>
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-form>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansFamilytab",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,
      edit: false,

      nextOfKinDetailsWasSend: true,
      maritalStatusWasSend: true,

      // Next Of Kin
      nextOfKin: {
        // Full names
        fullNames: "",
        fullNamesRules: [
          (v) => !!v || "Required",
          (v) => (v && !this.nextOfKin.fullNamesHasError) || "",
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
        ],
        fullNamesHasError: false,
        fullNamesErrorMessage: "Full names",

        // Mobile phone
        mobilePhone: "",
        mobilePhoneRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.nextOfKin.mobilePhoneHasError) ||
            this.nextOfKin.mobilePhoneErrorMessage,
          (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Must be 10 numbers",
        ],
        mobilePhoneHasError: false,
        mobilePhoneErrorMessage: "Mobile phone",

        // Relationship
        relationship: "",
        relationshipRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.nextOfKin.relationshipHasError) ||
            this.nextOfKin.relationshipErrorMessage,
        ],
        relationshipHasError: false,
        relationshipErrorMessage: "Relationship",
      },

      // other
      other: {
        // Marital status
        maritalStatus: "",
        maritalStatusRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.other.maritalStatusHasError) ||
            this.other.maritalStatusErrorMessage,
        ],
        maritalStatusHasError: false,
        maritalStatusErrorMessage: "marital status",
      },
    };
  },

  watch: {
    // nextOfKin.fullNames
    "nextOfKin.fullNames"() {
      if (this.nextOfKin.fullNamesHasError) {
        this.nextOfKin.fullNamesHasError = false;
        this.nextOfKin.fullNamesErrorMessage = "";
      }

      this.nextOfKinDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // nextOfKin.mobilePhone
    "nextOfKin.mobilePhone"(val) {
      if (this.nextOfKin.mobilePhoneHasError) {
        this.nextOfKin.mobilePhoneHasError = false;
        this.nextOfKin.mobilePhoneErrorMessage = "";
      }

      this.nextOfKinDetailsWasSend = false;

      this.nextOfKin.mobilePhone = this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // nextOfKin.relationship
    "nextOfKin.relationship"() {
      if (this.nextOfKin.relationshipHasError) {
        this.nextOfKin.relationshipHasError = false;
        this.nextOfKin.relationshipErrorMessage = "";
      }

      this.nextOfKinDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // other.maritalStatus
    "other.maritalStatus"() {
      if (this.other.maritalStatusHasError) {
        this.other.maritalStatusHasError = false;
        this.other.maritalStatusErrorMessage = "";
      }

      this.maritalStatusWasSend = false;

      this.setReadyToSubmit();
    },
  },

  computed: {
    ...mapState({ auth: "auth", personalData: "personalData" }),

    ...mapGetters({
      getRelationshipTypesList: "list/getRelationshipTypesList",
      getMaritalStatusTypesList: "list/getMaritalStatusTypesList",
    }),
  },

  created() {
    this.nextOfKinDetailsWasSend = true;
    this.maritalStatusWasSend = true;

    this.readyToSubmit = false;

    this.setShowMessage(false);

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
    }),

    ...mapMutations({
      setShowMessage: "personalData/setShowMessage",

      setNextOfKinTabHasSuccess: "personalData/setNextOfKinTabHasSuccess",
    }),

    editData() {
      this.edit = !this.edit;

      this.nextOfKin.fullNames = this.auth.user.next_of_kin[0].fullnames;
      this.nextOfKin.mobilePhone = this.$helpers.formatMobileNumber(
        this.auth.user.next_of_kin[0].contact_number
      );
      this.nextOfKin.relationship = this.auth.user.next_of_kin[0].relationship;
      this.other.maritalStatus = this.auth.user.marital_status;
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.nextOfKin.fullNames === "" ||
        this.nextOfKin.fullNames === null ||
        this.nextOfKin.mobilePhone === "" ||
        this.nextOfKin.mobilePhone === null ||
        this.nextOfKin.relationship === "" ||
        this.nextOfKin.relationship === null ||
        this.other.maritalStatus === "" ||
        this.other.maritalStatus === null ||
        !this.$refs.form.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // validateForm
    async validateForm() {
      this.setShowMessage(false);

      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        var allowSendNextOfKinDetails = this.allowSendingNextOfKinDetails();
        var allowSendMaritalStatus = this.allowSendingMaritalStatus();

        if (allowSendNextOfKinDetails || allowSendMaritalStatus) {
          // NextOfKinDetails
          if (allowSendNextOfKinDetails) {
            // console.log("sendNextOfKinDetails");
            await this.sendNextOfKinDetails();
          } else {
            this.nextOfKinDetailsWasSend = true;
          }

          // MaritalStatus
          if (allowSendMaritalStatus) {
            // console.log("sendMaritalStatus");
            await this.sendMaritalStatus();
          } else {
            this.maritalStatusWasSend = true;
          }

          this.complete();
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "You did not make any changes.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        // ###############
        /* if (this.nextOfKinDetailsWasSend && this.maritalStatusWasSend) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "You did not make any changes.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          if (!this.nextOfKinDetailsWasSend) {
            await this.sendNextOfKinDetails();
          }

          if (!this.maritalStatusWasSend) {
            await this.sendMaritalStatus();
          }
        } */
      }
    },

    // allowSendingNextOfKinDetails
    allowSendingNextOfKinDetails() {
      var tempMobilePhone = this.$helpers.formatInputMobileNumber(
        this.$helpers.formatMobileNumber(
          this.auth.user.next_of_kin[0].contact_number
        )
      );

      return (
        this.nextOfKin.fullNames != this.auth.user.next_of_kin[0].fullnames ||
        this.nextOfKin.mobilePhone != tempMobilePhone ||
        this.nextOfKin.relationship !=
          this.auth.user.next_of_kin[0].relationship
      );
    },

    // allowSendingMaritalStatus
    allowSendingMaritalStatus() {
      return this.other.maritalStatus != this.auth.user.marital_status;
    },

    // complete
    async complete() {
      if (this.nextOfKinDetailsWasSend && this.maritalStatusWasSend) {
        await this.getApiUserDetails();

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Data changed",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.edit = false;

        this.validate();
      }
    },

    // validate
    validate() {
      this.setShowMessage(true);

      this.$vuetify.goTo(0);
    },

    // Send Next Of Kin Details
    async sendNextOfKinDetails() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("next-of-kin-details", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          id: this.auth.user.next_of_kin[0].id,
          address: "Undisclosed",
          contact_number: this.nextOfKin.mobilePhone,
          full_name: this.nextOfKin.fullNames,
          relationship: this.nextOfKin.relationship,
        });

        if (res.status == 200) {
          this.nextOfKinDetailsWasSend = true;

          // this.$swal({
          //   toast: true,
          //   position: "top-end",
          //   icon: "success",
          //   title: "Next Of Kin Details Data changed",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          // });

          // this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("full_name" in data) {
            this.nextOfKin.fullNamesHasError = true;
            this.nextOfKin.fullNamesErrorMessage = data.full_name[0];
          }

          if ("contact_number" in data) {
            this.nextOfKin.mobilePhoneHasError = true;
            this.nextOfKin.mobilePhoneErrorMessage = data.contact_number[0];
          }

          if ("relationship" in data) {
            this.nextOfKin.relationshipHasError = true;
            this.nextOfKin.relationshipErrorMessage = data.relationship[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.nextOfKinDetailsWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Marital Status
    async sendMaritalStatus() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("marital-status", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          marital_status: this.other.maritalStatus,
        });

        if (res.status == 200) {
          this.maritalStatusWasSend = true;

          // this.$swal({
          //   toast: true,
          //   position: "top-end",
          //   icon: "success",
          //   title: "Marital Status Data changed",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          // });

          // this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("marital_status" in data) {
            this.other.maritalStatusHasError = true;
            this.other.maritalStatusErrorMessage = data.marital_status[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.maritalStatusWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
