<template>
  <div class="personal-data">
    <div class="personal-information">
      <v-form ref="form" lazy-validation>
        <!-- Personal information -->
        <h3 class="header-h3">Personal information</h3>

        <div v-if="edit">
          <v-row>
            <!-- First name -->
            <v-col class="mt-2" cols="12">
              <!-- ID number -->
              <div class="mt-2">
                <p class="b-body-1 gray800--text mb-0 pb-0">ID number</p>
                <h6 class="input-text">{{ auth.idnumber }}</h6>
              </div>

              <!-- First name  -->
              <div class="mt-5">
                <p class="b-body-1 gray800--text mb-0 pb-0">First name</p>
                <h6 class="input-text">{{ auth.user.firstnames }}</h6>
              </div>

              <!-- Surname -->
              <v-card class="mt-5" width="362" flat>
                <p class="label-text mb-1">Surname</p>

                <v-text-field
                  v-model="surname"
                  :rules="surnameRules"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                >
                </v-text-field>
              </v-card>

              <!-- Province -->
              <v-card class="mt-4 mb-n8" width="362" flat>
                <p class="label-text mb-1">Province</p>

                <v-select
                  v-model="province"
                  :rules="provinceRules"
                  :items="getProvincesList"
                  placeholder="Select..."
                  height="48px"
                  full-width
                  outlined
                  dense
                ></v-select>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- ID number -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">ID number</p>
            <h6 class="input-text">{{ auth.idnumber }}</h6>
          </div>

          <!-- First name  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">First name</p>
            <h6 class="input-text">{{ auth.user.firstnames }}</h6>
          </div>

          <!-- Surname  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Surname</p>
            <h6 class="input-text">{{ auth.user.surname }}</h6>
          </div>

          <!-- Province  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Province</p>
            <h6 class="input-text">{{ auth.user.province }}</h6>
          </div>
        </div>

        <!-- Сontacts -->
        <h3 class="header-h3 mt-11">Сontacts</h3>

        <div v-if="edit">
          <v-row>
            <v-col cols="12">
              <!-- Mobile Phone -->
              <v-card class="mt-4" max-width="170" flat>
                <p class="label-text mb-1">Mobile Phone</p>

                <v-text-field
                  v-model="mobilePhone"
                  :rules="mobilePhoneRules"
                  maxlength="12"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>

                <p v-if="readyToSubmit" class="b-body-2 mt-n6 gray700--text">
                  We'll send an SMS code to confirm
                </p>
              </v-card>

              <!--  E-mail  -->
              <v-card class="mt-4" width="362" flat>
                <p class="label-text mb-1">E-mail</p>

                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  height="48px"
                  full-width
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <!-- Mobile phone  -->
          <div class="mt-2">
            <p class="b-body-1 gray800--text mb-0 pb-0">Mobile phone</p>
            <h6 class="input-text">
              {{ $helpers.formatMobileNumber(auth.user.cellular) }}
            </h6>
          </div>

          <!-- Email  -->
          <div class="mt-5">
            <p class="b-body-1 gray800--text mb-0 pb-0">Email</p>
            <h6 class="input-text">{{ auth.user.email }}</h6>
          </div>
        </div>

        <!-- edit -->
        <div v-if="edit" class="d-flex flex-column flex-sm-row">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <!-- Save -->
            <v-btn
              @click="validateFormForDesktop()"
              class="b-btn-text white--text text-capitalize mt-4"
              width="169"
              height="48"
              color="primary"
              :disabled="!readyToSubmit"
            >
              Save
            </v-btn>

            <!-- Reject -->
            <v-btn
              @click="edit = !edit"
              class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
              width="169"
              height="48"
              color="primary"
              outlined
            >
              Reject
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <!-- Save -->
              <v-btn
                @click="validateFormForMobile()"
                class="b-btn-text white--text text-capitalize mt-4"
                width="100%"
                height="48"
                color="primary"
                :disabled="!readyToSubmit"
              >
                Save
              </v-btn>

              <!-- Reject -->
              <v-btn
                @click="edit = !edit"
                class="b-btn-text white--text text-capitalize mt-4 ml-0 ml-sm-6"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                Reject
              </v-btn>
            </v-card>
          </div>
        </div>

        <div v-else class="mt-8">
          <!-- Desktop -->
          <div class="hidden-xs-only">
            <v-btn
              @click="editData()"
              width="175"
              height="48"
              color="primary"
              outlined
            >
              <div class="d-flex">
                <v-img
                  alt="pen"
                  class="pen"
                  src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                  max-width="24"
                  contain
                />

                <h6 class="b-btn-text primary--text text-capitalize ml-2">
                  Edit
                </h6>
              </div>
            </v-btn>
          </div>

          <!-- Mobile -->
          <div class="hidden-sm-and-up">
            <v-card width="362" flat>
              <v-btn
                @click="editData()"
                width="100%"
                height="48"
                color="primary"
                outlined
              >
                <div class="d-flex">
                  <v-img
                    alt="pen"
                    class="pen"
                    src="@/assets/img/dashboard/pages/personal-data/pen.svg"
                    max-width="24"
                    contain
                  />

                  <h6 class="b-btn-text primary--text text-capitalize ml-2">
                    Edit
                  </h6>
                </div>
              </v-btn>
            </v-card>
          </div>
        </div>

        <!-- Newsletter  -->
        <h3 class="header-h3 mt-9">Newsletter</h3>

        <div class="mt-2">
          <!-- <v-switch v-model="newsletter" background-color="white">
            <template v-slot:label>
              <span class="b-body-1">Subscribe to updates from Lime</span>
            </template>
          </v-switch> -->

          <div class="d-flex">
            <!-- Subscribe on -->
            <div
              v-if="newsletter"
              @click="changeNewsletter(true)"
              class="d-flex align-center pointer"
            >
              <div class="custom-switch"></div>
              <div class="custom-switch-primary"></div>
            </div>

            <!-- Subscribe off  -->
            <div
              v-else
              @click="changeNewsletter(false)"
              class="d-flex align-center pointer"
            >
              <div class="custom-switch-main"></div>
              <div class="custom-switch"></div>
            </div>

            <div class="b-body-1 ml-1">Subscribe to updates from Lime</div>
          </div>
        </div>
      </v-form>

      <!-- Desktop -->
      <v-dialog v-model="dialog" width="600" persistent>
        <div class="card white">
          <div class="dialog-card pa-3">
            <v-card class="pa-3" flat>
              <!-- Phone confirmation -->
              <h3 class="header-h3">Phone confirmation</h3>
              <p class="b-body-1 gray800--text">
                The code was sent to the number
                <span class="font-weight-bold gray900--text">
                  {{ $helpers.formatMobileNumber(mobilePhone) }}
                </span>
              </p>

              <!-- SMS-code -->
              <p class="label-text mb-1">SMS-code</p>

              <div class="d-flex flex-column flex-sm-row align-sm-center">
                <v-card width="170" flat>
                  <!-- SMS-code -->
                  <v-form ref="form2" lazy-validation>
                    <v-text-field
                      class="py-0 my-0 inputPrice"
                      v-model="otp"
                      :rules="otpRules"
                      type="number"
                      onKeyPress="if(this.value.length==5) return false;"
                      height="48px"
                      full-width
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-form>
                </v-card>

                <!-- Get a new code -->
                <!-- allowOtpReSend -->
                <p v-if="!allowOtpReSend" class="b-body-1 ml-sm-5 mt-sm-n1">
                  <span class="font-weight-bold gray600--text"
                    >Get a new code</span
                  >
                  <countdown :time="time">
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </p>

                <!-- allowOtpReSend -->
                <p
                  v-else
                  @click="resetPasswordOtp()"
                  class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
                >
                  Get a new code
                </p>
              </div>
            </v-card>

            <v-img
              @click="closeDialog()"
              alt="times"
              class="times pointer"
              src="@/assets/img/dashboard/pages/personal-data/times.svg"
              max-width="24"
              contain
            />
          </div>
        </div>
      </v-dialog>

      <!-- Mobile -->
      <v-bottom-sheet v-model="bottomSheet">
        <v-sheet class="px-6 py-3 card2">
          <!-- Icon -->
          <div class="d-flex justify-center">
            <v-card color="gray400" width="48" height="4" flat tile> </v-card>
          </div>

          <div class="mt-4 pb-1">
            <!-- Phone confirmation -->
            <h3 class="desktop-h1">Phone confirmation</h3>
            <p class="b-body-1 gray800--text mt-4">
              The code was sent to the number
              <span class="font-weight-bold gray900--text text-no-wrap">
                {{ $helpers.formatMobileNumber(mobilePhone) }}
              </span>
            </p>

            <!-- SMS-code -->
            <p class="label-text mb-1">SMS-code</p>

            <div class="d-flex flex-column flex-sm-row align-sm-center">
              <v-card width="170" flat>
                <!-- SMS-code -->
                <v-form ref="form2" lazy-validation>
                  <v-text-field
                    class="py-0 my-0 inputPrice"
                    v-model="otp"
                    :rules="otpRules"
                    type="number"
                    onKeyPress="if(this.value.length==5) return false;"
                    height="48px"
                    full-width
                    outlined
                    required
                    dense
                  >
                  </v-text-field>
                </v-form>
              </v-card>

              <!-- Get a new code -->
              <!-- allowOtpReSend -->
              <p v-if="!allowOtpReSend" class="b-body-1 ml-sm-5 mt-sm-n1">
                <span class="font-weight-bold gray600--text"
                  >Get a new code</span
                >
                <countdown :time="time">
                  <template slot-scope="props">
                    {{ props.minutes }}:{{ props.seconds }}
                  </template>
                </countdown>
              </p>

              <!-- allowOtpReSend -->
              <p
                v-else
                @click="resetPasswordOtp()"
                class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
              >
                Get a new code
              </p>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansPersonalinformation",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,
      edit: false,

      dialog: false,
      bottomSheet: false,
      isDesktop: true,

      personalDetailsWasSend: true,
      mobilePhoneWasSend: true,
      emailWasSend: true,

      // Surname
      surname: "",
      surnameRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.surnameHasError) || this.surnameErrorMessage,
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length <= 25 || "Can not be more than 25 characters",
      ],
      surnameHasError: false,
      surnameErrorMessage: "",

      // Province
      province: "",
      provinceRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.provinceHasError) || this.provinceErrorMessage,
      ],
      provinceHasError: false,
      provinceErrorMessage: "",

      // Mobile Phone
      mobilePhone: "",
      mobilePhoneRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.mobilePhoneHasError) || this.mobilePhoneErrorMessage,
        (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        (v) => v.length == 12 || "Must be 10 numbers",
      ],
      mobilePhoneHasError: false,
      mobilePhoneErrorMessage: "",

      // Email
      email: "",
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => (!!v && !this.emailHasError) || this.emailErrorMessage,
      ],
      emailHasError: false,
      emailErrorMessage: "",

      newsletter: true,
      dataSend: true,

      otpSend: false,
      allowOtpReSend: false,

      // otp
      otp: "",
      otpRules: [
        (v) => !!v || "",
        (v) => v.length == 5 || "SMS-code is incorrect ",
        (v) => (!!v && !this.otpHasError) || this.otpErrorMessage,
      ],
      otpHasError: false,
      otpErrorMessage: "otp",

      countDown: 0,
      time: 0,
    };
  },

  computed: {
    ...mapState({ auth: "auth", personalData: "personalData" }),

    ...mapGetters({
      getProvincesList: "list/getProvincesList",
    }),
  },

  created() {
    this.newsletter = !this.auth.user.exclude_from_marketing;

    this.setShowMessage(false);

    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // surname
    surname() {
      if (this.surnameHasError) {
        this.surnameHasError = false;
        this.surnameErrorMessage = "";
      }

      this.personalDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // province
    province() {
      if (this.provinceHasError) {
        this.provinceHasError = false;
        this.provinceErrorMessage = "";
      }

      this.personalDetailsWasSend = false;

      this.setReadyToSubmit();
    },

    // mobilePhone
    mobilePhone(val) {
      if (this.mobilePhoneHasError) {
        this.mobilePhoneHasError = false;
        this.mobilePhoneErrorMessage = "";
      }

      this.mobilePhoneWasSend = false;

      this.mobilePhone = this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // email
    email() {
      if (this.emailHasError) {
        this.emailHasError = false;
        this.emailErrorMessage = "";
      }

      this.emailWasSend = false;

      this.setReadyToSubmit();
    },

    // bottomSheet
    bottomSheet(val) {
      if (val == false) {
        this.getApiUserDetails();
      }
    },

    // otp
    otp() {
      if (this.otpHasError) {
        this.otpHasError = false;
        this.otpErrorMessage = "";
      }

      if (this.otp.length == 5) {
        this.verifyCellular();
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      setShowMessage: "personalData/setShowMessage",

      setPersonalDetailsTabHasSuccess:
        "personalData/setPersonalDetailsTabHasSuccess",
    }),

    editData() {
      this.setShowMessage(false);

      this.edit = !this.edit;

      this.surname = this.auth.user.surname;
      this.province = this.auth.user.province;
      this.mobilePhone = this.$helpers.formatMobileNumber(
        this.auth.user.cellular
      );
      this.email = this.auth.user.email;
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.surname === "" ||
        this.surname === null ||
        this.province === "" ||
        this.province === null ||
        this.mobilePhone === "" ||
        this.mobilePhone === null ||
        this.email === "" ||
        this.email === null ||
        !this.$refs.form.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // validateFormForDesktop
    async validateFormForDesktop() {
      this.isDesktop = true;

      this.validateForm();
    },

    // validateFormForMobile
    async validateFormForMobile() {
      this.isDesktop = false;

      this.validateForm();
    },

    // validateForm
    async validateForm() {
      this.setShowMessage(false);

      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.personalDetailsWasSend = false;
        this.mobilePhoneWasSend = false;
        this.emailWasSend = false;

        var allowSendPersonalDetails = this.allowSendingPersonalDetails();
        var allowSendEmail = this.allowSendingEmail();
        var allowSendMobilePhone = this.allowSendingMobilePhone();

        if (
          allowSendPersonalDetails ||
          allowSendMobilePhone ||
          allowSendEmail
        ) {
          // allowSendingPersonalDetails
          if (allowSendPersonalDetails) {
            // console.log("sendingPersonalDetails");
            await this.sendPersonalDetails();
          } else {
            this.personalDetailsWasSend = true;
          }
          // allowSendingEmail
          if (allowSendEmail) {
            // console.log("allowSendingEmail");
            await this.sendEmail();
          } else {
            this.emailWasSend = true;
          }

          // personalDetailsWasSend and emailWasSend
          if (this.personalDetailsWasSend && this.emailWasSend) {
            if (this.countDown > 0 && !allowSendMobilePhone) {
              if (this.isDesktop) {
                this.dialog = true;
              } else {
                this.bottomSheet = true;
              }
            } else {
              // allowSendingMobilePhone
              if (allowSendMobilePhone) {
                // console.log("allowSendingMobilePhone");
                await this.sendMobilePhone();
              } else {
                this.mobilePhoneWasSend = true;
              }
            }
          }

          this.complete();
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "You did not make any changes.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // countDownTimer
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.allowOtpReSend = true;
      }
    },

    // changeNewsletter
    changeNewsletter(val) {
      this.newsletter = !val;
    },

    // allowSendingPersonalDetails
    allowSendingPersonalDetails() {
      return (
        this.surname != this.auth.user.surname ||
        this.province != this.auth.user.province ||
        this.newsletter != !this.auth.user.exclude_from_marketing
      );
    },

    // allowSendingEmail
    allowSendingEmail() {
      // .trim().toLowerCase()

      return this.email != this.auth.user.email;
    },

    // allowSendingMobilePhone
    allowSendingMobilePhone() {
      var tempMobilePhone = this.$helpers.formatInputMobileNumber(
        this.$helpers.formatMobileNumber(this.auth.user.cellular)
      );

      return this.mobilePhone != tempMobilePhone;
    },

    // closeDialog
    closeDialog() {
      this.dialog = false;

      this.getApiUserDetails();
    },

    // complete
    async complete() {
      if (
        this.personalDetailsWasSend &&
        this.mobilePhoneWasSend &&
        this.emailWasSend
      ) {
        await this.getApiUserDetails();

        this.edit = false;

        this.setShowMessage(true);

        this.$vuetify.goTo(0);

        if (this.isDesktop) {
          this.dialog = false;
        } else {
          this.bottomSheet = false;
        }

        this.password = "";
        this.otp = "";

        // console.log("complete");
      }
    },

    // Send Personal Details
    async sendPersonalDetails() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("personal-details", {
          idnumber: this.auth.idnumber,
          session_key: this.auth.session_key,
          lastname: this.surname,
          province: this.province,
          exclude_from_marketing: !this.newsletter ? 1 : 0,
        });

        if (res.status == 200) {
          this.personalDetailsWasSend = true;
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("sendPersonalDetails", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("lastname" in data) {
            this.surnameHasError = true;
            this.surnameErrorMessage = data.lastname[0];
          }

          if ("province" in data) {
            this.provinceHasError = true;
            this.provinceErrorMessage = data.province[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.personalDetailsWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Email
    async sendEmail() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("contact-detail", {
          idnumber: this.auth.idnumber,
          session_key: this.auth.session_key,
          email: this.email,
        });

        if (res.status == 200) {
          this.emailWasSend = true;
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("sendEmail", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("email" in data) {
            this.emailHasError = true;
            this.emailErrorMessage = data.email[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.emailWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Mobile Phone
    async sendMobilePhone() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        const res = await this.axios.post("change-primary-cell", {
          idnumber: this.auth.idnumber,
          session_key: this.auth.session_key,
          cellular: this.mobilePhone,
        });

        if (res.status == 200) {
          // this.mobilePhoneWasSend = true;
          this.getApiUserDetails();

          await this.verifyCellularOtp();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("sendMobilePhone", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("cellular" in data) {
            this.mobilePhoneHasError = true;
            this.mobilePhoneErrorMessage = data.cellular[0];
          }

          this.$refs.form.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.mobilePhoneWasSend = false;
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // verifyCellularOtp
    async verifyCellularOtp() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        let res = await this.axios.post("verify-cellular-otp", {
          idnumber: this.auth.idnumber,
          session_key: this.auth.session_key,
          cellular: this.mobilePhone,
        });

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "OTP Has Been Send To Your Mobile Number",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          if ("dev_otp" in res.data) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Your OTP is " + res.data.dev_otp,
              showConfirmButton: false,
              timer: 30000,
              timerProgressBar: true,
            });
          }

          this.countDown = 2 * 60;
          this.time = 2 * 60 * 1000;

          this.countDownTimer();

          this.otpSend = true;
          this.allowOtpReSend = false;

          if (this.isDesktop) {
            this.dialog = true;
          } else {
            this.bottomSheet = true;
          }

          this.otp = "";

          // console.log("res 200", res);
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 422) {
          // console.log("verifyCellularOtp", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("cellular" in data) {
            this.mobilePhoneHasError = true;
            this.mobilePhoneErrorMessage = data.cellular[0];

            // console.log(this.mobilePhoneErrorMessage);
          }

          this.error = true;

          this.$refs.form.validate();
          this.setReadyToSubmit();

          // console.log("error.response", error.response);
          // console.log("data", data);
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // verifyCellular
    async verifyCellular() {
      if (!this.auth.loggedIn) {
        return;
      }

      this.overlay = true;

      try {
        let res = await this.axios.post("verify-cellular", {
          idnumber: this.auth.idnumber,
          session_key: this.auth.session_key,
          cellular: this.mobilePhone,
          otp: this.otp,
          replace_primary: "1",
        });

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your Password Has Been Reset",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          // console.log("res 200", res);
          this.mobilePhoneWasSend = true;

          this.complete();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 422) {
          // console.log("verifyCellularOtp", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("cellular" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.cellular[0],
            });
          }

          if ("otp" in data) {
            this.otpHasError = true;
            this.otpErrorMessage = data.otp[0];

            // console.log(this.otpErrorMessage);
          }

          this.$refs.form2.validate();

          // console.log("data", data);
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card {
  position: relative;

  .times {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.card2 {
  /* Neutral / 00 */

  background: #ffffff;
  /* Shadows / XL modal ↑ */

  box-shadow: 0px 0px 32px rgba(61, 61, 61, 0.12),
    0px -32px 32px rgba(61, 61, 61, 0.24);
  border-radius: 8px 8px 0px 0px;
}

.custom-switch,
.custom-switch-main,
.custom-switch-primary {
  box-sizing: border-box;
}

.custom-switch,
.custom-switch-main {
  background: #ffffff;
  border: 1px solid #cccccc;
}

.custom-switch-main,
.custom-switch-primary {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  z-index: 1;
}

.custom-switch {
  width: 40px;
  height: 12px;
  border-radius: 49px;
}

.custom-switch-main {
  margin-right: -26px;
}

.custom-switch-primary {
  background: #5d9922;
  margin-left: -26px;
}

.desktop-h1 {
  //styleName: Header desktop/H1;
  font-family: "Raleway";
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
}
</style>
