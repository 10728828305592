<template>
  <div class="personal-data">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <!-- Success Message -->
            <v-col
              v-if="personalData.showMessage"
              class="message-card"
              cols="12"
            >
              <div class="success50 ml-n4 mr-n6 ml-md-n6 mr-md-0">
                <v-card
                  class="transparent px-6 py-3"
                  width="100%"
                  height="47"
                  flat
                  tile
                >
                  <h5 class="header-h5 success500--text">{{ message }}</h5>
                </v-card>
              </div>
            </v-col>

            <!-- Personal data -->
            <v-col cols="12">
              <h1 class="header-h1">Personal data</h1>
            </v-col>

            <v-col class="mt-n8" cols="12">
              <!-- tabs -->
              <v-tabs
                class="localfix"
                active-class="gray800--text"
                color="primary"
              >
                <!-- tabs-slider -->
                <v-tabs-slider class="mt-n2"></v-tabs-slider>

                <!-- Personal Details -->
                <v-tab
                  @click="changeTab('Personal Details')"
                  class="b-body-1 pl-0 pr-2 text-capitalize"
                >
                  Personal Details
                </v-tab>

                <!-- Banking & Employment -->
                <v-tab
                  @click="changeTab('Banking & Employment')"
                  class="b-body-1 text-capitalize px-2"
                >
                  Banking & Employment
                </v-tab>

                <!-- Next of kin -->
                <v-tab @click="changeTab('Next of kin')" class="b-body-1 px-2">
                  N<span class="text-lowercase">ext of kin</span>
                </v-tab>

                <!-- Password -->
                <v-tab
                  @click="changeTab('Password')"
                  class="b-body-1 pl-2 pr-0"
                >
                  P<span class="text-lowercase">assword</span>
                </v-tab>

                <!-- Your Details	 -->
                <v-tab-item class="mt-4">
                  <v-card flat>
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          v-if="personalData.showMessage"
                          class="transparent px-6 py-3 mb-3 mb-md-4 mt-n6 mt-md-0"
                          width="100%"
                          height="47"
                          flat
                          tile
                        ></v-card>

                        <PersonalInformation />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!-- Solvency Information -->
                <v-tab-item class="mt-4">
                  <v-card flat>
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          v-if="personalData.showMessage"
                          class="transparent px-6 py-3 mb-3 mb-md-4 mt-n6 mt-md-0"
                          width="100%"
                          height="47"
                          flat
                          tile
                        ></v-card>

                        <SolvencyInformation
                          redirect="dashboard/personal-data"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!-- Family -->
                <v-tab-item class="mt-4">
                  <v-card flat>
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          v-if="personalData.showMessage"
                          class="transparent px-6 py-3 mb-3 mb-md-4 mt-n6 mt-md-0"
                          width="100%"
                          height="47"
                          flat
                          tile
                        ></v-card>

                        <FamilyTab redirect="dashboard/personal-data" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!-- Password -->
                <v-tab-item class="mt-4">
                  <v-card flat>
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          v-if="personalData.showMessage"
                          class="transparent px-6 py-3 mb-3 mb-md-4 mt-n6 mt-md-0"
                          width="100%"
                          height="47"
                          flat
                          tile
                        ></v-card>

                        <PasswordTab />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import FamilyTab from "@/components/dashboard/pages/personal-data/FamilyTab";
import PasswordTab from "@/components/dashboard/pages/personal-data/PasswordTab";
import PersonalInformation from "@/components/dashboard/pages/personal-data/PersonalInformation";
import SolvencyInformation from "@/components/dashboard/pages/personal-data/SolvencyInformation";

export default {
  name: "LimeLoansPersonaldata",

  components: {
    FamilyTab,
    PasswordTab,
    PersonalInformation,
    SolvencyInformation,
  },

  data() {
    return {
      showMessage: true,
      message: "Data changed",
    };
  },

  computed: {
    ...mapState({ personalData: "personalData" }),
  },

  created() {
    this.setShowMessage(false);

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapMutations({
      setShowMessage: "personalData/setShowMessage",

      setPersonalDetailsTabHasSuccess:
        "personalData/setPersonalDetailsTabHasSuccess",
      setBankingAndEmploymentTabHasSuccess:
        "personalData/setBankingAndEmploymentTabHasSuccess",
      setNextOfKinTabHasSuccess: "personalData/setNextOfKinTabHasSuccess",
      setPasswordTabHasSuccess: "personalData/setPasswordTabHasSuccess",
    }),

    // changeTab
    changeTab(tab) {
      this.message = tab;

      if (tab == "Password") {
        this.message = "Password changed";
        this.showMessage = false;
      } else {
        this.message = "Data changed";
        this.showMessage = true;
      }

      this.setShowMessage(false);

      // console.log("tab", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-card {
  position: absolute;
  top: 134px;
  z-index: 1;
}

@media only screen and (max-width: 960px) {
  .message-card {
    top: 101px;
  }
}
</style>
